import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import ErrorMessage from "../../Components/errormsg";
import { API } from "aws-amplify";
// import { useAppContext } from "../../useContext";
import "../HelpContact/helpContact.css";

// imports originally here but not used
// import ReactPlayer from "react-player";

const HelpContact = (props) => {
  // const { email, provider } = useAppContext();

  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const [formSubmitted, setFormSubmitted] = useState(true);

  // eslint-disable-next-line no-unused-vars
  var handleEditButtonClick = () => {
    setFormSubmitted(!formSubmitted);
  };

  var submitForm = (data) => {
    console.log(data);
    API.post("referall-provider", "addhelpform", {
      body: {
        data,
        // , email: email, provider: provider
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    setFormSubmitted(!formSubmitted);
  };

  return (
    <div className="width80Percent">
      <h1 className="header">Contact Us</h1>
      {formSubmitted ? null : <p>Thank you for your message.</p>}
      <p>We'll get back to you in 1-2 business days.</p>
      {formSubmitted ? (
        <form className=" width50Percent ">
          {/* <label>Your name</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_user_name"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_user_name} />
                <label>Your organization</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_organization_name"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_organization_name} />
                <label>Your email</label>
                <input
                    className="width100Percent height35 border1Grey0 marginBottom15"
                    name="provider_email"
                    ref={register({ required: true })}
                />
                <ErrorMessage error={errors.provider_email} /> */}
          <p>
            <label htmlFor="provider_message">
              <strong>
                Add your message <br></br>
                <span className="label-help-text">
                  If you would like a response, please add your email address.
                </span>
              </strong>
            </label>
          </p>
          <textarea
            className="width100Percent border1Grey0 marginBottom26"
            name="provider_message"
            id="provider_message"
            ref={register({ required: true, maxLength: 1000 })}
            rows="12"
            cols="60"
          />
          <div className="flexRow justifySpaceBetween width100Percent">
            <Link to="/help">
              <button className="cancel-button">Cancel</button>
            </Link>
            <Link to="/help">
              <button
                className={
                  !formState.isValid
                    ? "save-helpform-disabled"
                    : "save-helpform-button"
                }
                type="submit"
                disabled={!formState.isValid}
                onClick={handleSubmit(submitForm)}
              >
                Save
              </button>
            </Link>
          </div>

          {/* For button dimming: Will need conditional rendering for the className/color. If string in the useState for yourName, yourOrg, yourEmail, and addYourMessage all have a .length that is >= 2, then give the button a className for the #A10070 color, else give the button a className for the #D5D8DD color. */}
        </form>
      ) : null}
    </div>
  );
};

export default HelpContact;
