// eslint-disable-next-line no-unused-vars
import React from // , { useState }
"react";
// import { Link } from "react-router-dom";
import "../Help/help.css";
// eslint-disable-next-line no-unused-vars
// import { useForm } from "react-hook-form";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
// import ErrorMessage from "../../Components/errormsg";
// import ReactPlayer from "react-player";

// imports originally here but not used
// import { API } from "aws-amplify";
// import { useAppContext } from "../../useContext";

// for accordion drop down
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Help1 = (props) => {
  // closing accordion when another is opened
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <h1>Help &#38; Tutorials</h1>
      <br></br>
      <h2>Overview of the ResourceFull Platform</h2>{" "}
      <div className="help-key-wrapper">
        <div>
          <FilterAltIcon className="help-key-icon" />
          <p>
            Search with the filters to find organizations by types of services
            offered and eligibility criteria including location, age, and
            genders served.
          </p>
        </div>
        <div>
          <VisibilityIcon className="help-key-icon" />
          <p>View provider profiles and see contact information.</p>
        </div>
        <div>
          <MailOutlinedIcon className="help-key-icon" />
          <p>Contact our team to let us know what you think.</p>
        </div>
      </div>
      {/*<ReactPlayer url="https://www.youtube.com/watch?v=mwqIZ0eafgQ" /> 
      <Link className="link-instructions">
        <p>Read step-by-step instructions instead</p>
      </Link> */}
      <h2>FAQs</h2>
      <div className="helpAccordionSection">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleExpansionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>
              <strong>What type of organization is ResourceFull?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="client-data">
                ResourceFull is a software product built by AnnieCannons, a
                registered nonprofit organization. Powered by code and humans we
                bring together people in need of services with those who provide
                them.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleExpansionChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>
              <strong>Is ResourceFull free?</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="client-data">
                ResourceFull will always be free to people seeking services.
                Currently there are no costs to providers. All features
                currently on the app will remain free to providers.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleExpansionChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>
              <strong>
                Why can’t my organization receive intakes on ResourceFull?
              </strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p className="client-data">
                Some organizations have chosen or been advised to not receive
                intakes on the platform. This may be because of internal intake
                processes, capacity or because your organization is a covered
                entity. If you think that should change, contact us.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Help1;
